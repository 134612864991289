@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'), url(fonts/AvenirNext.ttc) format('truetype');
}

// taken from https://smxemail.com (our brand identity)
$primary: #303cbf;
$secondary: #636363;
$blue: $primary;
$cyan: #4dbbff;
$dark: #333333;

$body-color: $dark;
$headings-line-height: 1.5;
$headings-font-weight: 600;
$font-family-sans-serif: 'Avenir Next', 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;

$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2.15;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.15;

$accordion-button-active-bg: rgba($cyan, 0.2);
$accordion-button-active-color: $primary;

@import '~bootstrap/scss/bootstrap';

$level-good: #7bbd40;
$level-warning: #efb54c;
$level-danger: #df362d;

body {
  padding: $spacer * 2.5 $spacer * 2;
}

.color-logo {
  fill: url(#color-logo);
}

h1 {
  line-height: 1;
}

.alert {
  margin-top: $spacer * 1.5;
  width: fit-content;
  min-width: 10%;

  .spinner-border {
    margin-left: $spacer;
    vertical-align: middle;
  }
}

.side-wrapper {
  display: none;
  position: relative;

  &::before {
    position: absolute;
    height: 160vh;
    content: '';
    width: 2px;
    top: 0;
    right: 50%;
    background: linear-gradient($gray-400, rgba($gray-400, 0));
  }

  &::after {
    position: absolute;
    height: 20px;
    width: 20px;
    content: '';
    border-radius: 50%;
    background-clip: padding-box;
    top: 0;
    right: 50%;
    transform: translateX(9px);
    background: $gray-400;
  }
}

.circle {
  display: flex;
  border-radius: 50%;
  margin: auto;

  &.good {
    background: $level-good;
  }

  &.warning {
    background: $level-warning;
  }

  &.danger {
    background: $level-danger;
  }

  &.undefined {
    background: rgba($secondary, 0.2);
  }

  h4 {
    margin: auto;
  }
}

.circles a {
  text-decoration: none;
  color: $dark;
  display: inline-block;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.3s;
  }
}

.metrics {
  // margin: 0 2 * $spacer;

  ul {
    columns: 2;
    list-style: none;
    padding: 0;

    li {
      border-top: 1px $gray-300 solid;
      line-height: 3 * $spacer;

      &::before {
        width: 10px;
        height: 10px;
        display: inline-block;
        content: '';
        margin-right: $spacer / 2;
      }

      em {
        margin-left: $spacer / 2;
        float: right;
        font-style: normal;
      }

      &.good {
        &::before {
          border-radius: 50%;
          background-color: $level-good;
        }

        em {
          color: $level-good;
        }
      }

      &.warning {
        &::before {
          background-color: $level-warning;
        }

        em {
          color: $level-warning;
        }
      }

      &.danger {
        &::before {
          background-color: $level-danger;
        }

        em {
          color: $level-danger;
        }
      }
    }
  }
}

section.intro {
  margin: $spacer * 2 0 $spacer * 2;
}

@media (min-width: 992px) {
  .accordion-body > section {
    margin-left: 5.5 * $spacer;
  }
}
h2.accordion-header button {
  font-size: 1.2rem;
}

.score-description {
  font-size: $h5-font-size;
}
